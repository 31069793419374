import { Component, OnInit, Input } from '@angular/core';
import { Widget } from '../../../dashboard/decorators/widget.decorator';
import { WidgetLayout } from '../../../dashboard/models/widget-layout';
import { SpeedMonitorViewMode } from 'chronos-shared';
import { KPIInfoWithOrder } from 'projects/chronos-live/src/app/services/custom/_models/kpi-info';
import { Observable } from 'rxjs';
import { WorkCenterCachedService, WorkCenterData } from 'chronos-core-client';

@Widget('Speed Monitor')
@Component({
  selector: 'app-speed-monitor-widget',
  templateUrl: './speed-monitor-widget.component.html',
  styleUrls: ['./speed-monitor-widget.component.scss']
})
export class SpeedMonitorWidgetComponent implements OnInit {
  public screenMode: SpeedMonitorViewMode = SpeedMonitorViewMode.Large;
  public externalWorkCenterId: number;
  public workCenterId: number;

  constructor(private workCenterService: WorkCenterCachedService) {}

  @Input() public basicSettings: any;
  @Input() public customSettings: any;
  public widgetConfig: any;
  public widgetLayout: WidgetLayout;
  public kpisList: KPIInfoWithOrder[] = [];
  public displayTime: number;
  public workCenterList: WorkCenterData[];

  public ngOnInit(): void {
    if (this.customSettings) {
      this.externalWorkCenterId = this.customSettings.find((x) => x.key === 'workCenterId')?.value;

      // const activeSubscription1 =
      this.getWorkCenterList().subscribe((workCenterList) => {
        this.workCenterList = workCenterList;
        if (this.workCenterList) {
          this.workCenterList.forEach((element) => {
            if (element.externalWorkCenterId === this.externalWorkCenterId?.toString()) {
              this.workCenterId = element.id;
            }
          });
        }
      });

      this.kpisList = this.customSettings.find((x) => x.key === 'kpis').value ?? [];
      if (this.kpisList && this.kpisList.length > 0) {
        const kpis = JSON.parse(this.customSettings.find((x) => x.key === 'kpis').value);
        this.kpisList = kpis.filter((column) => {
          if (!column.hidden) {
            return column;
          }
        });
      }

      if (this.customSettings.find((x) => x.key === 'displayTime').value) {
        this.displayTime = this.customSettings.find((x) => x.key === 'displayTime').value;
      }
    }
  }

  public getWorkCenterList(): Observable<WorkCenterData[]> {
    return this.workCenterService.workCenterGetWorkCenterList();
  }
}
